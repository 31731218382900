<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-row class="no-gutters">
        <b-col>
          <h1 class="mr-sm-4 header-tablepage">EXTRA POINT DETAIL</h1>
        </b-col>
      </b-row>
      <div class="title-tabs mt-3">General Information</div>
      <b-container class="no-gutters bg-white">
        <div class="py-3">
          <b-row>
            <b-col sm="6">
              <InputText
                textFloat="Promotiom Name"
                placeholder="Promotiom Name"
                v-model="form.name"
                isRequired
                type="text"
                :isValidate="$v.form.name.$error"
                :v="$v.form.name"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <label class="ft-16"
                >Member Type <span class="text-danger">*</span></label
              >
              <div class="mt-2">
                <b-row>
                  <b-col sm="2" v-for="(member, i) in memberLevelList" :key="i">
                    <b-form-checkbox
                      class="text-black"
                      v-model="form.member_level_id"
                      name="member-type"
                      :value="parseInt(member.id)"
                    >
                      {{ member.name }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </div>
              <div class="text-error" v-if="$v.form.member_level_id.$error">
                Please select branch at least 1.
              </div>
            </b-col>
            <b-col sm="12" class="mt-3">
              <label class="ft-16">Products Participating in Promotions</label>
              <b-form-group class="d-flex mt-2">
                <b-form-radio-group
                  v-model="form.is_all_product"
                  name="product-radios"
                  class="text-black"
                  :options="isAllProductOption"
                ></b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col sm="12" v-if="form.is_all_product == 0">
              <div class="content-between">
                <div>
                  <b-button class="btn-select-branch" @click="openProductModal">
                    Select Product<font-awesome-icon
                      icon="chevron-right"
                      class="ml-2"
                    />
                  </b-button>
                  <span class="ml-2"
                    >Selected Product
                    {{ form.product_id.length | numeral("0,0") }}
                    Item</span
                  ><span
                    class="ml-2 text-error"
                    v-if="$v.form.product_id.$error"
                    >Please select product at least 1.</span
                  >
                </div>
                <button
                  @click="importProduct"
                  type="button"
                  class="btn button btn-mobile ml-2"
                >
                  <font-awesome-icon icon="file-import" class="d-md-none" />
                  <span class="d-none d-md-block">
                    <font-awesome-icon icon="file-import" class="pointer" />
                    <span class="btn-text">Import </span>
                  </span>
                </button>
              </div>
              <div class="mt-3">
                <b-table
                  responsive
                  striped
                  hover
                  :fields="fieldsProduct"
                  :items="productSelect"
                  :busy="isBusy"
                  show-empty
                  empty-text="Not Found."
                >
                  <template v-slot:cell(imageUrl)="data">
                    <div>
                      <div
                        v-if="data.item.imageUrl"
                        class="box-image"
                        v-bind:style="{
                          'background-image': 'url(' + data.item.imageUrl + ')',
                        }"
                      ></div>
                      <div v-else>-</div>
                    </div>
                  </template>
                  <template v-slot:cell(name)="data">
                    <div class="text-left">
                      <div v-if="data.item.name">
                        <p class="m-0">
                          {{ data.item.name }}
                        </p>
                        <p class="m-0 text-gray">
                          {{ data.item.barcode }}
                        </p>
                        <p class="m-0 text-gray">
                          {{ data.item.price | numeral("0,0.00") }}
                        </p>
                      </div>
                      <p v-else class="m-0">-</p>
                    </div>
                  </template>
                  <template v-slot:cell(delete)="data">
                    <span class="pointer" @click="deleteProduct(data.item)"
                      ><b-icon
                        icon="trash-fill"
                        class="text-bg-primary"
                      ></b-icon
                    ></span>
                  </template>
                  <template v-slot:table-busy>
                    <div class="text-center text-black my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong class="ml-2">Loading...</strong>
                    </div>
                  </template>
                </b-table>
              </div>
              <div>
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    class="form-inline justify-content-start"
                  >
                    <p class="mb-0 p-gray">
                      Showing
                      {{ rows > 0 ? showing + (filter.page - 1) : 0 }} to
                      {{ showingTo > rows ? rows : showingTo }} of
                      {{ rows }} entries
                    </p>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    class="form-inline justify-content-end"
                  >
                    <b-pagination
                      v-model="filter.page"
                      :total-rows="rows"
                      :per-page="filter.length"
                      first-text="First"
                      prev-text="Prev"
                      next-text="Next"
                      last-text="Last"
                      class="m-md-0"
                      @change="pagination"
                    ></b-pagination>
                    <b-form-select
                      class="ml-1"
                      v-model="filter.length"
                      @change="handleChangeTake"
                      :options="pageOptions"
                    ></b-form-select>
                  </b-col>
                </b-row>
              </div>
              <hr />
            </b-col>
            <b-col sm="12">
              <ModalSelectBranch
                v-if="branchList && branchList.length > 0"
                :list="branchList"
                @selectBranch="selectBranch"
                text="Select Branch"
                :field="form"
                :BranchSelectList="form.branch_id"
                :id="id == 0 ? 'noSelect' : id.toString()"
              />
              <div class="text-error" v-if="$v.form.branch_id.$error">
                Please select at least 1 branch.
              </div>
            </b-col>
            <b-col sm="6" class="mt-2">
              <label class="ft-16"
                >Start Date (DD/MM/YYYY)<span class="text-danger"
                  >*</span
                ></label
              >
              <div :class="['input-container']">
                <datetime
                  type="datetime"
                  :input-style="styleDatetime"
                  v-model="form.start_datetime"
                  placeholder="Please Select Date"
                  @close="changeStartDate"
                  ref="startDate"
                >
                </datetime>
                <div
                  class="icon-primary text-right"
                  @click="$refs.startDate.isOpen = true"
                >
                  <font-awesome-icon
                    icon="calendar-alt"
                    class="pointer color-primary"
                    color="#7160c4"
                  />
                </div>
              </div>
              <div class="text-error" v-if="$v.form.start_datetime.$error">
                Please input date.
              </div>
            </b-col>
            <b-col sm="6" class="mt-2">
              <label class="ft-16"
                >End Date (DD/MM/YYYY)<span class="text-danger">*</span></label
              >
              <div :class="['input-container']">
                <datetime
                  type="datetime"
                  :input-style="styleDatetime"
                  v-model="form.end_datetime"
                  placeholder="Please Select Date"
                  :min-datetime="form.start_datetime"
                  @close="onChangeEndDate"
                  ref="endDate"
                >
                </datetime>
                <div
                  class="icon-primary text-right"
                  @click="$refs.endDate.isOpen = true"
                >
                  <font-awesome-icon
                    icon="calendar-alt"
                    class="pointer color-primary"
                    color="#7160c4"
                  />
                </div>
              </div>
              <div class="text-error" v-if="$v.form.end_datetime.$error">
                Please input date.
              </div>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="title-tabs mt-3">Conditions</div>
      <b-container class="no-gutters bg-white">
        <div class="pt-3 pb-3">
          <b-row>
            <b-col sm="2">
              <b-form-group>
                <b-form-radio
                  v-model="form.extra_point_type"
                  name="extra-point-type"
                  :value="1"
                  >Multiplier</b-form-radio
                >
              </b-form-group>
            </b-col>
            <b-col sm="1">
              <b-form-group>
                <b-form-radio
                  v-model="form.extra_point_type"
                  name="extra-point-type"
                  :value="2"
                  >Point</b-form-radio
                >
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <div
                class="text-error"
                v-if="form.multiplier.length < 1 && checkFormStatus"
              >
                Please create at least 1 condition.
              </div>
            </b-col>
          </b-row>
          <div
            v-for="(multiple, i) in form.multiplier"
            :key="i"
            class="px-3 pt-3 bg-gray"
          >
            <b-row>
              <b-col sm="6"
                ><strong class="condition-title"
                  >Condition #{{ i + 1 }}</strong
                ></b-col
              >
              <b-col sm="6" class="d-flex justify-content-end">
                <span class="pointer" @click="deleteMultiplier(i)"
                  ><b-icon icon="trash-fill" class="text-bg-primary"></b-icon
                ></span>
              </b-col>
            </b-row>
            <b-row class="p-1">
              <b-col
                ><InputText
                  :textFloat="
                    form.extra_point_type == 1
                      ? 'Number of Multiplier'
                      : 'Number of Points'
                  "
                  :placeholder="
                    (form.extra_point_type == 1
                      ? 'Number of Multiplier'
                      : 'Number of Points') + ' (Enter a number of numbers)'
                  "
                  v-model="multiple.multiplier"
                  isRequired
                  type="number"
                  :isValidate="
                    $v.form.multiplier.$each.$iter[i].multiplier.$error
                  "
                  :v="$v.form.multiplier.$each.$iter[i].multiplier"
              /></b-col>
              <b-col
                ><InputText
                  textFloat="Minimum Purchase/Bill Receipt (Baht)"
                  placeholder="0"
                  v-model="multiple.minimum_requirement"
                  isRequired
                  type="number"
                  :isValidate="
                    $v.form.multiplier.$each.$iter[i].minimum_requirement.$error
                  "
                  :v="$v.form.multiplier.$each.$iter[i].minimum_requirement"
              /></b-col>
            </b-row>
          </div>
          <div class="mt-3 d-flex justify-content-center align-items-center">
            <b-button class="btn-add-condition" @click="addCondition"
              ><font-awesome-icon
                icon="plus-square"
                title="create-btn"
                class="white-color mr-0 mr-sm-1"
              /><span class="d-none d-sm-inline">New Condition.</span></b-button
            >
          </div>
        </div>
      </b-container>
      <div class="no-gutters bg-white mt-3 py-2 px-3">
        <b-form-checkbox
          switch
          v-model="form.status"
          class="radio-active"
          size="lg"
          :value="1"
          :unchecked-value="0"
        >
          <span class="ml-2 main-label">{{
            form.status ? "Active" : "Inactive"
          }}</span>
        </b-form-checkbox>
      </div>
      <FooterAction @submit="checkForm()" routePath="/extrapoint" />
    </div>
    <ProductModal v-on:submitProduct="submitProduct" ref="ProductListModal" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <b-modal
      v-model="importModal"
      no-close-on-backdrop
      centered
      title="Import Product"
      hide-footer
    >
      <div>
        <b-row class="no-gutters">
          <b-col md="12">
            <UploadFileV2
              textFloat="File"
              placeholder="Please Choose File"
              format="excel"
              name="file"
              text="*Please upload only file .xlsx less than 10 MB"
              isRequired
              v-on:onFileChange="onFileChange"
              v-on:delete="deleteFile"
              :fileName="formImport.filename"
              v-model="formImport.excel"
              :v="$v.formImport.excel"
              id="uploadfile"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-button size="md" class="btn-cancel" @click="importModal = false"
              >Cancel</b-button
            >
          </b-col>
          <b-col class="text-right">
            <b-button
              id="upload"
              class="main-color btn-save color-btn"
              size="md"
              :disabled="isDisable"
              @click="importProduct"
              >Upload</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  required,
  requiredIf,
  minValue,
  minLength,
} from "vuelidate/lib/validators";
import * as moment from "moment/moment";
import OtherLoading from "@/components/loading/OtherLoading";
import InputText from "@/components/inputs/InputText";
import ModalSelectBranch from "@/components/ModalSelectBranch";
import ProductModal from "./components/ProductModal.vue";
import ModalAlertError from "@/components/modal/ModalAlertError";
export default {
  name: "ExtraPointDetail",
  components: {
    OtherLoading,
    InputText,
    ModalSelectBranch,
    ProductModal,
    ModalAlertError,
  },

  data() {
    return {
      id: parseInt(this.$route.params.id),
      branchList: [],
      productSelect: [],
      originalProductSelect: [],
      originalMemberLevelSelect: [],
      originalBranchSelect: [],
      rows: 0,
      showing: 1,
      showingTo: 10,
      filter: {
        page: 1,
        length: 10,
        start: 0,
        DeleteProductList: [],
        onlyInRedeem: 1,
        search: "",
        selectProductList: [],
      },
      form: {
        add_branch_id: null,
        add_member_level_id: null,
        add_product_id: null,
        branch_group_id: 0,
        branch_id: [],
        delete_branch_id: null,
        delete_member_level_id: null,
        delete_product_id: null,
        end_datetime: "",
        extra_point_type: 1,
        id: 0,
        is_all_product: 1,
        member_level_id: [],
        multiplier: [],
        name: "",
        product_group_id: 1,
        product_id: [],
        start_datetime: "",
        status: 0,
      },
      fieldsProduct: [
        {
          key: "imageUrl",
          label: "Image",
        },
        {
          key: "name",
          label: "Product Name (SKU)",
        },
        {
          key: "delete",
          label: "",
        },
      ],
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      styleDatetime: {
        border: "none",
      },
      isAllProductOption: [
        { text: "All Products", value: 1 },
        { text: "Selected Item", value: 0 },
      ],
      memberLevelList: [],
      modalMessage: "",
      checkFormStatus: false,
      isBusy: false,
      isLoading: true,
      importModal: false,
      isDisable: false,
      formImport: {
        excel: "",
        file_name: "",
      },
    };
  },
  validations: {
    form: {
      name: { required },
      product_id: {
        required: requiredIf(function () {
          return this.form.is_all_product == 0;
        }),
        minLength: minLength(1),
      },
      member_level_id: { required, minLength: minLength(1) },
      branch_id: { required, minLength: minLength(1) },
      start_datetime: { required },
      end_datetime: { required },
      multiplier: {
        $each: {
          multiplier: { required, minValue: minValue(1) },
          minimum_requirement: { required, minValueZero: minValue(0) },
        },
      },
    },
    formImport: {
      excel: { required },
      file_name: { required },
    },
  },
  async created() {
    if (this.id == 0) {
      await this.getBranchList(this.form, this.form.branch_group_id);
      this.isLoading = false;
    } else {
      await this.getDetail();
      await this.getProductSelect();
    }
    await this.getMemberLevelList();
  },
  methods: {
    async getDetail() {
      this.isLoading = true;
      await this.$store.dispatch("getExtraPointDetail", this.id);
      let data = this.$store.state.extraPoint.extraPointDetail;
      if (data.result == 1) {
        this.form = data.detail;
        this.originalProductSelect = this.form.product_id;
        this.originalMemberLevelSelect = this.form.member_level_id;
        this.originalBranchSelect = this.form.branch_id;
        this.getBranchList(this.form, this.form.branch_group_id);
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    },
    async getBranchList(field, id) {
      await this.$store.dispatch("getBranch", parseInt(id));
      this.branchList = this.$store.state.coupon.branchList.detail;

      field.options = [];
      this.branchList.forEach((e, index) => {
        e.branch.forEach((b, i) => {
          if (b.is_check) {
            field.options.push({ text: b.name, value: b.name });
          }
        });
      });
    },
    selectBranch(value) {
      this.form.branch_id = value;
    },
    async getMemberLevelList() {
      await this.$store.dispatch("getMemberLevel");
      let data = this.$store.state.automation.memberLevel;
      if (data.result == 1) {
        this.memberLevelList = data.detail;
      }
    },
    async getProductSelect() {
      this.isBusy = true;
      this.filter.start = (this.filter.page - 1) * this.filter.length;
      this.filter.selectProductList = this.form.product_id.map((e) => {
        return { id: e, point: 0 };
      });
      await this.$store.dispatch("getListProductSelect", this.filter);
      const data = this.$store.state.redeem.productSelectList;
      if (data.result == 1) {
        this.productSelect = await data.detail.data;
        await this.productSelect.forEach((e, index) => {
          e.index = index;
        });
        this.rows = data.detail.totalSelectProduct;
      } else {
        this.productSelect = [];
        this.rows = 0;
      }
      this.isBusy = false;
    },
    async openProductModal() {
      await this.$refs.ProductListModal.show(this.form.product_id);
    },
    submitProduct(select) {
      select = select.map((e) => {
        return e.id;
      });

      this.filter.DeleteProductList = this.filter.DeleteProductList.filter(
        (el) => !select.includes(el)
      );

      this.form.product_id = select;
      this.filter.page = 1;
      this.getProductSelect();
    },
    async deleteProduct(data) {
      await this.filter.DeleteProductList.push(
        this.productSelect[data.index].id
      );
      await this.productSelect.splice(data.index, 1);
      this.form.product_id = this.form.product_id.filter(
        (item) => item != data.id
      );
      this.productSelect.forEach((e, index) => {
        e.index = index;
      });
      if (this.productSelect.length == 0) {
        if (this.filter.page > 1) {
          this.filter.page -= 1;
        }
      }
      this.getProductSelect();
    },
    addCondition() {
      this.form.multiplier.push({ minimum_requirement: 0, multiplier: 0 });
    },
    deleteMultiplier(index) {
      this.form.multiplier.splice(index, 1);
    },
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.showing = this.filter.page;
        this.showingTo = this.filter.length;
      } else {
        this.showing = value * this.filter.length - this.filter.length;
        this.showingTo = this.showing + this.filter.length;
      }
      this.getProductSelect();
    },
    handleChangeTake(length) {
      this.filter.page = 1;
      this.filter.length = length;
      this.showingTo = this.filter.length;
      this.getProductSelect();
    },
    changeStartDate() {
      if (!this.form.end_datetime) {
        this.form.end_datetime = this.form.start_datetime;
      }
      if (this.form.start_datetime >= this.form.end_datetime) {
        this.form.end_datetime = moment(this.form.start_datetime)
          .add("day", 0)
          .format();
      }
    },
    onChangeEndDate() {
      if (!this.form.start_datetime) {
        this.form.start_datetime = this.form.end_datetime;
      }
    },
    checkForm() {
      this.checkFormStatus = true;
      this.$v.form.$touch();
      if (this.$v.form.$error || this.form.multiplier.length == 0) {
        return;
      } else {
        if (this.id == 0) {
          this.createExtraPoint();
        } else {
          this.updateExtraPoint();
        }
      }
    },
    async createExtraPoint() {
      this.isLoading = true;
      let body = {
        name: this.form.name,
        start_datetime: moment(this.form.start_datetime).add(7, "hours"),
        end_datetime: moment(this.form.end_datetime).add(7, "hours"),
        status: this.form.status,
        extra_point_type: this.form.extra_point_type,
        is_all_product: this.form.is_all_product,
        add_product_id: this.form.product_id,
        add_member_level_id: this.form.member_level_id,
        add_branch_id: this.form.branch_id,
        multiplier: this.form.multiplier,
      };
      await this.$store.dispatch("createExtraPoint", body);
      let data = this.$store.state.extraPoint.resCreateExtraPoint;
      if (data.result == 1) {
        this.successAlert().then(() => this.$router.push("/extrapoint"));
      } else {
        this.errorAlert(data.message);
      }
      this.isLoading = false;
    },
    onFileChange(file) {
      this.formImport.filename = file.name;
      this.isDisable = false;
      const str =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.formImport.excel = reader.result.substring(
          str.length,
          reader.result.length
        );
      };
    },
    deleteFile(value) {
      this.formImport.excel = null;
      this.formImport.filename = null;
      this.isDisable = true;
    },
    async importProduct() {
      let payload = {
        excel_file: this.formImport.excel,
      };
      if (this.importModal) {
        this.$EventBus.$emit("showLoading");
        const result = await this.$Axios.post(
          `${process.env.VUE_APP_API}/import/getproductbyimport`,
          payload
        );
        if (result.data.result) {
          let product = [
            ...this.form.product_id.map((el) => {
              return { id: el };
            }),
            ...result.data.detail.productId.map((el) => {
              return { id: el };
            }),
          ];
          this.submitProduct(product);
          this.importModal = false;
        } else {
          this.errorAlert(result.data.message);
        }
        // this.$EventBus.$emit("showLoading");
        this.$EventBus.$emit("hideLoading");
      } else {
        this.importModal = true;
      }
    },
    async updateExtraPoint() {
      this.isLoading = true;
      let body = {
        id: this.id,
        name: this.form.name,
        start_datetime: moment(this.form.start_datetime).add(7, "hours"),
        end_datetime: moment(this.form.end_datetime).add(7, "hours"),
        status: this.form.status,
        extra_point_type: this.form.extra_point_type,
        is_all_product: this.form.is_all_product,
        add_product_id: [],
        delete_product_id: [],
        add_member_level_id: [],
        delete_member_level_id: [],
        add_branch_id: [],
        delete_branch_id: [],
        multiplier: this.form.multiplier,
      };
      body.add_product_id = this.form.product_id.filter(
        (item) => !this.originalProductSelect.includes(item)
      );
      body.delete_product_id = this.originalProductSelect.filter(
        (item) => !this.form.product_id.includes(item)
      );
      body.add_member_level_id = this.form.member_level_id.filter(
        (item) => !this.originalMemberLevelSelect.includes(item)
      );
      body.delete_member_level_id = this.originalMemberLevelSelect.filter(
        (item) => !this.form.member_level_id.includes(item)
      );
      body.add_branch_id = this.form.branch_id.filter(
        (item) => !this.originalBranchSelect.includes(item)
      );
      body.delete_branch_id = this.originalBranchSelect.filter(
        (item) => !this.form.branch_id.includes(item)
      );
      await this.$store.dispatch("updateExtraPoint", body);
      let data = this.$store.state.extraPoint.resUpdateExtraPoint;
      if (data.result == 1) {
        this.successAlert().then(() => this.$router.push("/extrapoint"));
      } else {
        this.errorAlert(data.message);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep label {
  margin-bottom: 2px;
}
.ft-16 {
  font-size: 16px;
  color: var(--font-main-color);
}
.bg-gray {
  background-color: #f7f7f7;
}
.text-gray {
  color: #939393;
}
.input-container.error {
  border-color: red !important;
}
// ::v-deep .branch-modal > div {
//   margin: 0px 0px 4px 0px !important;
// }
.condition-title {
  color: var(--primary-color);
  font-size: 16px;
}
.btn-add-condition {
  color: white;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
}
</style>
